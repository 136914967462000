<template>
  <div ref="pdfDom">
    <div>
      <!-- 工具栏 -->
      <div class="toolbar">
        <el-row type="flex" align="middle">
          <el-col :span="22">
            <span style="margin-left:10px">
              姓名： {{ userData.user_name }}
            </span>
            <span style="margin-left:10px">分数： {{ userData.score }} </span>
            <span style="margin-left:10px">位次： {{ userData.ranking }}</span>
            <span style="margin-left:20px">{{ userData.is_wenli == 1 ? '文科' : '理科' }}</span>
            <span style="margin-left:20px">
              批次：<span v-if="batch == 1">本科一批</span>
              <span v-if="batch == 2">本科二批</span>
              <span v-if="batch == 3">高职高专批</span>
            </span>
            <span style="margin-left:20px">学生电话：{{ userData.mobile }}</span>


          </el-col>
          <el-col :span="2">
            <div class="toobDiv">
              <el-button type="primary" plain size="mini" class="margin_right" @click="downLoalPdf">导出
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 志愿表 -->
      <div class="table" id="pdfDom" ref="pdfDom">
        <el-row type="flex" align="middle">
          <el-col :span="24">
            <table border v-for="(item, i) in record.college" :key="i">
              <tbody>
                <tr rowspan="7" class="th">
                  <td rowspan="7" style="width:130px; background:white">
                    <div class="px">
                      平行志愿{{ record.dg[i] }}
                    </div>
                    <div class="fc" v-if="item.tiaoji">
                      服从调剂
                    </div>
                    <div class="fc" v-else style="color:red"> 拒绝调剂 </div>

                    <div class="hw">
                      <el-button round size="mini" @click="moveUp(item, i)">上移</el-button>
                    </div>

                    <div class="hw">
                      <el-button round size="mini" @click="moveDown(item, i)">下移</el-button>
                    </div>

                    <div class="edit">
                      <el-button round size="mini" @click="getSpecialty(item)">编辑</el-button>
                    </div>
                    <div class="del">
                      <el-button round size="mini" type="danger" plain @click="delCollege(i)">删除</el-button>
                    </div>
                  </td>
                  <td rowspan="7" style="width:330px; background:white">
                    <div>
                      <p><strong>院校名称：{{ item.name }} </strong></p>
                      <p>所在地：{{ item.city_name }} </p>
                      <p>招生代号：{{ item.school_id }} </p>
                      <p>办学性质：{{ item.nature }} </p>
                      <p>院校计划：{{ item.plan_num }} </p>
                      <p>录取原则：{{ item.lqgz }}</p>
                      <p v-if="item.school_tip">院校标签：{{ item.school_tip }}</p>
                    </div>
                    <div>
                      <table class="school_table">
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>年份</td>
                          <td>计划数</td>
                          <td>投档分</td>
                          <td>位次</td>
                        </tr>
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>2023</td>
                          <td>{{ item.td_plan_num_1 }}</td>
                          <td>{{ item.td_min_score_1 }}</td>
                          <td>{{ item.td_ranking_1 }}</td>
                        </tr>
                        <tr style="border-bottom:1px solid #dddddd">
                          <td>2022</td>
                          <td>{{ item.td_plan_num_2 }}</td>
                          <td>{{ item.td_min_score_2 }}</td>
                          <td>{{ item.td_ranking_2 }}</td>
                        </tr>
                        <tr>
                          <td>2021</td>
                          <td>{{ item.td_plan_num_3 }}</td>
                          <td>{{ item.td_min_score_3 }}</td>
                          <td>{{ item.td_ranking_3 }}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="th" style="width:100px">专业序号</td>
                  <td class="th">专业代号/名称</td>
                  <td class="th" style="width:100px">专业备注</td>
                  <td class="th" style="width:100px">专业计划</td>
                  <td class="th" style="width:100px">学费标准</td>
                </tr>
                <tr v-for="( val, key) in record.specialty[i]" :key="key" style="height:81px">
                  <td>专业{{ word[key] }}</td>
                  <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
                  <td v-if="val.remarks">
                    <el-tooltip class="item" effect="dark" :content="val.remarks" placement="top">
                      <el-button size="mini">查看</el-button>
                    </el-tooltip>
                  </td>
                  <td v-else> </td>
                  <td>{{ val.plan_num }}</td>
                  <td>{{ val.tuition }}</td>
                </tr>
                <tr v-for="ddd in   5 - (record.specialty[i].length) " :key="ddd + i + 'adf'" style="height:81px">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
      </div>

      <!-- 弹出框-选择专业 -->
      <el-dialog :visible.sync="dialogVisible" width="1200px" destroy-on-close>
        <mojar :collegeData='collegeData' v-if="dialogVisible" @Refresh='RefreshTable()' :form="form"
          :EqualScore="EqualScore" :fencha="fencha" @close="CloseDiaLog" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Mojar from "./mojar_page/index";
export default {
  props: {
    batch: String,
    form: Object,
    EqualScore: Array,
    fencha: Number,
    len: Number,
  },
  name: "",
  computed: { ...mapState(["record", 'userData']) },
  data() {
    return {
      dialogVisible: false,
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", '十', '十一', '十二'],
      batchList: [
        { name: "本科一批", value: 1 },
        { name: "本科二批", value: 2 },
        { name: "高职高专批", value: 3 },
      ],
      collegeData: [],
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      select_code: '',
      report_list: {
        dg: [],
        college: [],
        id: [],
        sp: [],
        specialty: [],
      },
    };
  },
  components: {
    Mojar,
  },
  created() {
    this.test()


  },
  methods: {

    test() {
      console.log(this.record)
      this.word.forEach(item => {
        this.testr(item)
      })
      this.$store.commit('setRecord', this.report_list)
      this.updateReport();
    },

    testr(dengji) {
      this.record.dg.forEach((item, i) => {
        if (item == dengji) {
          this.report_list.college.push(this.record.college[i]);
          this.report_list.dg.push(this.record.dg[i]);
          this.report_list.id.push(this.record.id[i]);
          this.report_list.sp.push(this.record.sp[i]);
          this.report_list.specialty.push(this.record.specialty[i]);
        }
      })
    },

    //专业弹窗回调
    CloseDiaLog() {
      this.dialogVisible = false;
    },

    moveEidt(item) {
      item.visibless = !item.visibless;
      this.$forceUpdate()
    },

    moveUp(item, i) {
      item.visibless = false;
      if (i != 0) {
        this.record.id[i] = this.record.id.splice(i - 1, 1, this.record.id[i])[0]
        this.record.sp[i] = this.record.sp.splice(i - 1, 1, this.record.sp[i])[0]
        this.record.college[i] = this.record.college.splice(i - 1, 1, this.record.college[i])[0]
        this.record.specialty[i] = this.record.specialty.splice(i - 1, 1, this.record.specialty[i])[0]
      }
      this.$store.commit('setRecord', this.record)
      this.updateReport();
      this.$forceUpdate();
    },

    moveDown(item, i) {
      item.visibless = false;
      if (i != this.record.college.length - 1) {
        this.record.id[i] = this.record.id.splice(i + 1, 1, this.record.id[i])[0];
        this.record.sp[i] = this.record.sp.splice(i + 1, 1, this.record.sp[i])[0];
        this.record.college[i] = this.record.college.splice(i + 1, 1, this.record.college[i])[0];
        this.record.specialty[i] = this.record.specialty.splice(i + 1, 1, this.record.specialty[i])[0];
      }
      this.$store.commit('setRecord', this.record)
      this.updateReport();
      this.$forceUpdate();
    },

    //查看专业
    getSpecialty(item) {
      if (item.name == '暂无') {
        this.$message({
          type: "error",
          message: "请先选择院校",
        });
        return false
      }
      this.collegeData = item;
      this.dialogVisible = true;
    },

    delCollege(i) {
      this.record.id.splice(i, 1);
      this.record.sp.splice(i, 1);
      this.record.college.splice(i, 1);
      this.record.specialty.splice(i, 1);
      this.record.dg.splice(i, 1);
      this.$store.commit('setRecord', this.record)
      this.updateReport();
    },

    //获取志愿表列表
    getReportList() {
      this.$fecth
        .post("user_info/getReportList", {
          user_id: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          res = res.data;
          this.tableData = res.list;
          this.total = res.count;
        });
    },

    //下载PDF
    downLoalPdf() {
      var routeData = this.$router.resolve({
        path: "/PdfVolunteer",
        query: {
          is_wenli: this.userData.is_wenli,
          user_id: this.userData.id,
          batch: this.batch,
          score: this.userData.score,
        },
      });
      window.open(routeData.href, "_blank");
    },

    //修改就更新志愿表
    updateReport() {
      console.log(this.$store.state.record, 12312)
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          is_wenli: this.userData.is_wenli,
          ranking: this.userData.ranking,
          batch: this.batch,
          record: JSON.stringify(this.record)
        })
    },
  },
};
</script>

<style lang="less">
.toobDiv {

  .el-button--mini,
  .el-button--small {
    font-size: 16px;
  }

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 4px 4px;
  }
}

.dialogName {
  .content {
    padding: 40px 100px;
    visibility: middle;
    border-bottom: #ddd solid 1px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .el-dialog__header {
    border-bottom: #ddd solid 1px;
  }

  .el-dialog__body {
    padding: 0;
    font-size: 16px;
  }
}
</style>

<style scoped lang='less'>
.toolbar {
  background: #f4f7fc;
  padding: 20px 40px;

  .toobDiv {
    .margin_right {
      margin-left: 3px;
    }
  }
}

.table {
  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.school_table {
  border-collapse: collapse;
  border: 0 !important;
  text-align: center;

  td {
    border-bottom: 1px gray !important;
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}

.hw,
.edit,
.del {
  text-align: center;
  margin-bottom: 10px;
}
</style>