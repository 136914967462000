<template>
  <div>
    <user-data-form v-if="status" />
    <div class="main">
      <div class="info">
        <el-row type='flex' align='middle'>
          <el-col :span="18">
            <user-equal :fencha="fencha"></user-equal>
          </el-col>

          <el-col :span="6" :offset="0">
            <span>填报批次：</span>
            <span class="tuijian"></span>
            <span class="select">
              <el-select v-model="batch" placeholder="请选择" class="searchBatch" @change="changeBatch" style="width:100px"
                size="mini">
                <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </span>
          </el-col>
        </el-row>

        <div class="nav">
          <div :class="{ act: this.nav_id == 1 }" @click="nav_id = 1">
            <p>智能填报</p>
          </div>

          <div :class="{ act: this.nav_id == 2 }" @click="nav_id = 2">
            <p>自由填报</p>
          </div>

          <div :class="{ act: this.nav_id == 3 }" @click="nav_id = 3">
            <p>学员志愿表</p>
          </div>
        </div>
      </div>

      <div v-if="nav_id == 1">
        <intelligence-module :len="len" :batch="batch" :fencha="fencha" v-if="status">
        </intelligence-module>
      </div>

      <div v-if="nav_id == 2">
        <free-module :len="len" :batch="batch" :fencha="fencha" v-if="status"></free-module>
      </div>

      <div v-if="nav_id == 3">
        <volunteer-report :len="len" :batch="batch" :fencha="fencha" v-if="status" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import UserEqual from "./components/UserEqual.vue";
import UserDataForm from "./components/UserData";
import IntelligenceModule from "./components/intelligence-module/index";
import FreeModule from "./components/free-model/index";
import VolunteerReport from "./components/VolunteerReport.vue";
export default {
  name: "",
  components: {
    UserEqual,
    UserDataForm,
    IntelligenceModule,
    FreeModule,
    VolunteerReport,
  },
  computed: { ...mapState(["userData"]) },
  data() {
    return {
      nav_id: 1,
      //等同分
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
      ],

      batch: "1",
      len: 12,
      equalScore: {},
      fencha: 0,
      status: 0
    };
  },
  created() {
    //获取用户信息
    this.user_id = this.$route.query.id;
    this.getUserInfo(this.user_id);

    if (this.$route.query.batch) {
      this.batch = this.$route.query.batch;

      localStorage.setItem("batch", this.$route.query.batch);
    } else {
      localStorage.setItem("batch", 1);
    }
    this.batch = localStorage.getItem('batch')

    //设置批次
  },
  methods: {
    ...mapMutations(['setUserData', 'setOldLineScore', 'setEqualScore']),

    //获取用户信息
    getUserInfo(id) {
      if (this.$route.query.nav_id) {
        this.nav_id = 2
      }
      let data = {
        id: id,
      };
      this.$fecth.post("user/getUserInfo", data).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.status = 1
          this.setUserData(data)
          this.getRecode()
          //等同分
          this.getEqualList();
          //每年录取分数线
          this.getOldCityScore();
        } else {
          this.showTips(0, msg);
        }
      });
    },

    //修改批次
    changeBatch(e) {
      this.batch = e;
      localStorage.setItem("batch", e);
      this.getRecode()
      this.getOldCityScore();
    },

    //获取志愿表
    getRecode() {
      let data = {
        user_id: this.user_id,
        is_wenli: this.userData.is_wenli,
        ranking: this.userData.ranking,
        score: this.userData.score,
        batch: this.batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        if (res.data.record) {
          this.record = JSON.parse(res.data.record)
        } else {
          this.record = {
            id: [],
            dg: [],
            sp: [],
            college: [],
            specialty: [],
          }
        }
        this.$store.commit('setRecord', this.record)
      })
    },

    //根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          this.fencha = res.data[0].score;

          this.setOldLineScore(res.data)
        });
    },

    // 获取等同分
    getEqualList() {
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: this.userData.is_wenli,
          score: this.userData.score,
        })
        .then((res) => {
          this.equalScore = res.data
          this.setEqualScore(res.data)
        });
    },

    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
.nav {
  margin-top: 20px;
  margin-bottom: 30px;

  div {
    width: 219px;
    border: 1px solid #459df6;
    color: black;
    padding: 8px 57px;
    display: inline-block;
    margin-right: 20px;
    border-radius: 10px 10px 0 0;
  }

  .act {
    color: white;
    background: #459df6;
  }
}

.act {
  color: white;
  background: #459df6;
}

.info {
  font-size: 16px;
  position: relative;

  span {
    display: inline-block;
  }

  margin: 20px 0;

  .tuijian {
    position: absolute;
    height: 20px;
    width: 55px;
    top: -16px;
    right: 10px;
    font-size: 10px;
  }
}

.main {
  padding: 20px;
  background: white;
  margin-top: 7px;
}
</style>
