<template>
  <div>
    <div class="info">
      <user-equal :fencha="fencha"></user-equal>
    </div>
    <el-divider class="divider_CP"></el-divider>
    <div>
      <span style="font-size: 16px; font-weight: bold;color:#000000">{{ collegeData.name }}</span>
      <span style="margin-left: 25px;color:#75787b">所在地:{{ collegeData.city_name }}</span>
      <span style="margin-left: 25px;color:#75787b">招生代号:{{ collegeData.school_id }}</span>
      <span style="margin-left: 25px;color:#75787b">录取规则:{{ collegeData.lqgz }}</span>
    </div>
    <el-row style="margin-top: 25px" :gutter="20" class="plan_tab1">
      <el-col :span="12">
        <el-row type="flex" align="middle" style="margin-bottom: 19px">
          <el-col :span="3"> 服从调剂 </el-col>
          <el-col :span="5">
            <el-radio-group v-model="collegeData.tiaoji" @change="getTiaoji()">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-table :data="planData" class="tab_plan" height="325"
          :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
          :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
          <el-table-column label="今年招生计划">
            <el-table-column prop="specialty_id" label="代码" width="49px" align="center">
              <template slot-scope="scope">
                {{ scope.row.specialty_id | interpo }}
              </template>
            </el-table-column>
            <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="remarks" label="专业备注" show-overflow-tooltip align="center" width="60">
            </el-table-column>
            <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
            </el-table-column>
            <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <div v-if="change">
                  <el-button v-if="spList.indexOf(scope.row.specialty_id) > -1" @click="chooseSpecialty(scope.row)"
                    size="mini" type="primary">
                    已选择 {{ spList.indexOf(scope.row.specialty_id) + 1 }}
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </div>
                <div v-else>
                  <el-button @click="chooseSpecialty(scope.row)" size="mini">填报1</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <MojarOld :school_id='collegeData.school_id' :select_code="collegeData.select_code" :searchKey='searchKey'
          :collegeData="collegeData" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MojarOld from "./MojarOld";
import UserEqual from "../UserEqual";
import { mapState } from "vuex";
export default {
  props: ["collegeData", 'fencha'],
  name: "",
  components: {
    MojarOld, UserEqual
  },
  data() {
    return {
      planData: [],
      loading: true,
      searchKey: "",
      tiaoji: true,
      change: false,
      spList: []
    };
  },
  computed: { ...mapState(["record", 'userData']) },
  mounted() {
    let index = this.record.id.indexOf(this.collegeData.select_code)
    if (index > -1) {
      this.collegeData.tiaoji = this.record.college[index].tiaoji
      if (this.record.sp[index]) {
        this.spList = this.record.sp[index]
      } else {
        this.spList = []
      }
      this.change = true
      this.$forceUpdate
    }
    this.batch = JSON.parse(localStorage.getItem("batch"));
    this.getPlan();
  },

  methods: {
    //获取数据
    getPlan() {
      if (this.collegeData.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer/getPlan", {
            select_code: this.collegeData.select_code,
            batch: this.batch,
          })
          .then((res) => {
            res = res.data;
            this.planData = res;
            this.loading = false;
          });
      }
    },

    //选择专业
    chooseSpecialty(item) {
      // //判断是否选择志愿位置
      let index = this.record.id.indexOf(this.collegeData.select_code);
      if (index > -1) {
        let sp_id = this.spList.indexOf(item.specialty_id);
        if (sp_id > -1) {
          this.record.sp[index].splice(sp_id, 1);
          this.record.specialty[index].splice(sp_id, 1)
        } else {
          //判断是否已经选了5个志愿
          let splen = this.spList.length;
          if (splen == 5) {
            this.$alert("每个志愿最多填报5个专业！", "提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
          this.searchKey = item.specialty_name
          this.record.sp[index].push(item.specialty_id);
          this.record.specialty[index].push(item);
          this.$store.commit('setRecord', this.record)
        }
        this.updateReport()
      } else {
        this.$alert("请先选择院校", "提示", {
          confirmButtonText: "确定",
        }).catch((msg) => {
          console.log(msg);
        });
      }
    },

    updateReport() {
      console.log(this.$store.state.record, 12312)
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          is_wenli: this.userData.is_wenli,
          ranking: this.userData.ranking,
          batch: this.batch,
          record: JSON.stringify(this.record)
        })
    },

    getTiaoji() {
      this.$store.commit('setRecord', this.record);
      this.updateReport()
    },

    refersh() {

    },
  },
};
</script>

<style scoped lang='less'>
.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }
}
</style>

<style lang="less" >
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.divider_CP {
  margin: 10px 0;
}
</style>