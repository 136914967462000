<template>
  <div>
    <table class="tb1" border="1px">
      <v-head />
      <tbody v-if="oldLineScore[0]">
        <tr>
          <td rowspan="3" class="school_data">
            <p>{{ item.name }}【{{ item.school_id }}】<span v-if="item.degree != null && batch == 3">*</span> </p>
            <p>办学性质:{{ item.nature }}录取规则:{{ item.lqgz }} </p>
            <p v-if="item.city_name">所在地:{{ item.city_name }}</p>
            <p v-if="item.school_tip">院校标签：{{ item.school_tip }}</p>
            <p v-if="item.td_ranking_1">上年投档最低位次:{{ item.td_ranking_1 }}</p>
          </td>
          <td rowspan="3">{{ item.plan_num }}</td>
          <!--                    年份-->
          <td>{{ item.year_1 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_1 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_1 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_1 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_1 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_1 - oldLineScore["1"].score) | Zero }}</td>
          <!--  最低分-->
          <td>{{ item.min_score_1 }}</td>
          <!--  最低分位次-->
          <td style="color: red">{{ item.ranking_1 }}</td>

          <!--  最低分线差-->
          <td style="color: red">
            {{ item.min_score_1 - oldLineScore["1"].score | Zero }}
          </td>
          <td rowspan="3">
            <el-popover v-model="item.visible" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseCollege(item, word[h - 1])">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.id.indexOf(item.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(item)">
                志愿{{ record.dg[record.id.indexOf(item.select_code)] }}
              </div>
              <div v-else slot="reference" class="btn" @click="changeAlert(item)">填报为</div>
            </el-popover>

            <div class="text-c color-7">
              <div class="btn" @click="getSpecialty(item)">
                查看专业
              </div>
            </div>
          </td>
        </tr>
        <tr class="bg_line">
          <td>{{ item.year_1 - 1 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_2 }}</td>
          <!--  录取数-->
          <td>{{ item.matriculate_2 }}</td>
          <!--  最高分-->
          <td>{{ item.max_score_2 }}</td>
          <!--  平均分-->
          <td>{{ item.mean_score_2 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_2 - oldLineScore["2"].score) | Zero }}</td>
          <!--  最低分-->
          <td>{{ item.min_score_2 }}</td>
          <!--  最低分位次-->
          <td style="color: red"> {{ item.ranking_2 }}</td>
          <!--  最低分线差-->
          <td style="color: red"> {{ item.min_score_2 - oldLineScore["2"].score | Zero }}</td>
        </tr>
        <tr>
          <td>{{ item.year_1 - 2 }}</td>
          <!--计划数 -->
          <td>{{ item.plan_num_3 }} </td>
          <!--  录取数-->
          <td>{{ item.matriculate_3 }} </td>
          <!--  最高分-->
          <td>{{ item.max_score_3 }} </td>
          <!--  平均分-->
          <td>{{ item.mean_score_3 }}</td>
          <!--  平均分线差-->
          <td>{{ (item.mean_score_3 - oldLineScore["3"].score) | Zero }}
          </td>
          <!--  最低分-->
          <td>{{ item.min_score_3 }}</td>
          <!--  最低分位次-->
          <td style="color: red"> {{ item.ranking_3 }} </td>
          <!--  最低分线差-->
          <td style="color: red"> {{ item.min_score_3 - oldLineScore["3"].score | Zero }} </td>
        </tr>
      </tbody>
    </table>
    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1200px">
      <mojar-page :collegeData="collegeData" :fencha="fencha" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import VHead from "./VHeadFree";
import mojarPage from "../mojar_page/index.vue";
export default {
  name: '',
  props: {
    item: Object,
    fencha: Number,
    len: Number,
  },
  computed: { ...mapState(["record", 'userData', 'oldLineScore']) },
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", '十', '十一', '十二'],
      collegeData: [],
      dialogVisible: false,
      batch: 1,
      yearList: [2022, 2021, 2020],
    };
  },
  components: {
    VHead, mojarPage,
  },
  filters: {
    Zero(e) {
      if (e > -100) {
        return e;
      } else {
        return "-";
      }
    },
  },

  created() {
    this.batch = localStorage.getItem('batch')
  },

  methods: {
    ...mapMutations(['setRecord']),
    //填报为
    changeAlert(item) {
      item.tiaoji = 1;
      item.visible = !item.visible;
      this.$forceUpdate();
    },

    chooseCollege(item, i) {
      let index = this.record.id.indexOf(item.select_code)
      if (index > -1) {
        this.record.id.splice(index, 1)
        this.record.dg.splice(index, 1)
        this.record.college.splice(index, 1)
        this.record.sp.splice(index, 1)
        this.record.specialty.splice(index, 1)
      }
      this.record.id.push(item.select_code)
      this.record.dg.push(i)
      this.record.college.push(item)
      this.record.sp.push([])
      this.record.specialty.push([])
      item.visible = false
      this.$store.commit('setRecord', this.record)
      this.$emit('refush')
      this.$forceUpdate();
    },
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang='less'>
.tb1 {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      background: #f4f7fc;
    }
  }
}

// 按钮
.btn {
  border-radius: 20px;
  border: 1px solid #409eff;
  color: #8c939d;
  width: 68px;
  margin: 3px auto 3px;
  font-size: 12px;
  line-height: 24px;
}

.schoolBtn {
  border-radius: 4px;
  background: #409eff;
  color: white;
}


//选择志愿框
.box-title {
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.box-item {
  padding: 10px 0;
  font-size: 14px;
}
</style>
