<template>
  <div>
    <conditional-search @onSearch='onSearch' />
    <div class="down">
      <div style="border:0;padding:0;font-size: 16px;">筛选志愿列表：</div>
      <div v-for="(i, k) in len" :key="k" @click="checkDj(i)" :class="{ act: dengjiList.indexOf(i) > -1 }">
        志愿{{ word[i - 1] }}
      </div>
      <div style="float:right;background:#2b7bf3;color:white;margin-right:0" @click="toPdf">导出筛选列表</div>
    </div>

    <!-- 院校列表 -->
    <div style="min-height: 100px">
      <college-list v-loading="loading" v-for="(item, i) in CollegeData" :item="item" :fencha="fencha" :len="len" :key="i"
        @refush=updateReport>
      </college-list>
    </div>

    <!-- 分页 -->
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="this.page.pageSize" layout=" prev, pager, next"
            :total="this.page.total" :current-page="this.currentPage">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CollegeList from "./CollegeList.vue";
import ConditionalSearch from "../ConditionalSearch.vue";
export default {
  name: '',
  props: ['len', 'batch', 'fencha'],
  components: {
    ConditionalSearch, CollegeList
  },
  data() {
    return {
      dengjiList: [1],
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", '十', '十一', '十二'],
      CollegeData: [],
      // 分页
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      currentPage: 1,

      check: {
        degree: null,
        city_id: [],
        nature_id: null,
        specific_code: [2, 7, 4, 5, 3],
        school_type_id: [],
        schoolTipId: [],
        max_ranking: '',
        min_ranking: '',
        input1: '',
        input2: ''
      },
    };
  },
  computed: { ...mapState(["userData", 'record']) },

  watch: {
    batch() {
      this.getList()
    }
  },

  created() {
    this.getList()
  },
  methods: {

    //判断是否条件筛选
    onSearch(item) {
      this.currentPage = 1;
      this.page.pageNum = 1;
      this.check = item;
      if (this.check.input1 || this.check.input2) {
        this.getLists();
      } else {
        this.getList();
      }
    },

    //选择志愿等级
    checkDj(e) {
      const index = this.dengjiList.indexOf(e);
      if (index > -1) {
        this.dengjiList.splice(index, 1);
      } else {
        this.dengjiList.push(e);
      }
      this.getList();
    },

    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    //条件搜索
    getList() {
      if (this.batch == 0) {
        return;
      }
      this.$fecth
        .post("volunteer/GetSchoolList", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          nature_id: this.check.nature_id,
          degree: this.check.degree,
          city_id: this.check.city_id,
          school_type_id: this.check.school_type_id,
          specific_code: this.check.specific_code,
          schoolTipId: this.check.schoolTipId,
          dengjiList: this.dengjiList,
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          res = res.data;
          res = this.getDengji(res);
          this.loading = false;
          this.CollegeData = res.lists;
          this.school_child = res.school_child;
          this.page.total = res.count;
        });
    },
    //搜索框搜索
    getLists() {
      if (this.batch == 0) {
        return;
      }
      let str1 = "volunteer/collegeSearch";
      let str2 = "volunteer/getMajorFirst";
      let url = "";
      let keyword = '';
      if (this.check.input1) {
        keyword = this.check.input1
        url = str1;
      } else {
        url = str2;
        keyword = this.check.input2
      }
      this.$fecth
        .post(url, {
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          keyword: keyword,
          batch: this.batch,
        })
        .then((res) => {
          res = res.data;
          res = this.getDengji(res);
          this.loading = false;
          this.CollegeData = res.lists;
          this.school_child = res.school_child;
          this.page.total = res.count;
        });
    },

    toPdf() {
      var routeData = this.$router.resolve({
        path: "/PdfRecommend",
        query: {
          name: this.userData.user_name,
          ranking: this.userData.ranking,
          score: this.userData.score,
          is_wenli: this.userData.is_wenli,
          batch: this.batch,
          check: JSON.stringify(this.check),
          dengjiList: this.dengjiList,
        },
      });
      window.open(routeData.href, "_blank");
    },

    //判断志愿等级
    getDengji(res) {
      res.lists.forEach((item) => {
        let num = Number(this.userData.score) + 5 - Number(item.entry_score);
        item.dengji = Math.ceil(num / 2.5);
      });
      return res;
    },

    //修改就更新志愿表
    updateReport() {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.$route.query.id,
          is_wenli: this.userData.is_wenli,
          ranking: this.userData.ranking,
          batch: this.batch,
          record: JSON.stringify(this.record)
        })
    },

  },
};
</script>

<style lang='less' scoped>
.act {
  color: white;
  background: #459df6;
}

.down {
  div {
    display: inline-block;
    margin-right: 3px;
    border: 1px solid #2b7bf3;
    padding: 2px 5px;
    font-size: 14px;
  }

  margin-bottom: 20px;
}
</style>